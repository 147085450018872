import React, { useEffect } from "react";
import BlockContainer from "../components/BlockContainer";
import BlockInfo from "../components/BlockInfo";
import Brands from "../components/Brands";
import CardContainer from "../components/CardContainer";
import CardInfo from "../components/CardInfo";
import Form from "../components/Form";
import Header from "../components/Header";
import IlustracionInfo from "../components/IlustracionInfo";
import LaptopBanner from "../components/LaptopBanner";
import PreguntasFrecuentes from "../components/PreguntasFrecuentes";
import TractorBanner from "../components/TractorBanner";
import laptop from "../assets/img/laptop.png";
import laptopMobile from "../assets/img/laptopMobile.png";
import tractorIcon from "../assets/img/tractor-icon.svg";
import blockInfo1 from "../assets/img/block-info-1.png";
import blockInfo2 from "../assets/img/block-info-2.png";
import BlockInfoText from "../components/BlockInfoText";
import bolsaIcon from "../assets/img/bolsa-icon.svg";
import tractor from "../assets/img/tractor.png";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <main>
      <Header />
      <CardContainer>
        <CardInfo
          iconPath="/img/responsive.svg"
          titulo="100% Digital"
          descripcion="Viví una experiencia digital de punta a punta."
        />
        <CardInfo
          iconPath="/img/grafico.svg"
          titulo="Simple"
          descripcion="Controlá tus operaciones financieras de manera fácil en un solo lugar."
        />
        <CardInfo
          iconPath="/img/celular.svg"
          titulo="Ágil"
          descripcion="Ofrecemos soluciones flexibles para que tomes la mejor decisión"
        />
      </CardContainer>
      <IlustracionInfo />
      <LaptopBanner
        imagen={laptop}
        imagenMobile={laptopMobile}
        icon={tractorIcon}
        titulo="Pagá y financiá la compra de tus insumos con:"
        badget1="Créditos a sola firma"
        badget2="Préstamos con cesión de forward"
        badget3="Pago con granos"
        texto="Nos adaptamos a tus necesidades ofreciéndote multiplicidad de vencimientos acorde a los ciclos productivos."
      />
      <BlockContainer>
        <BlockInfoText />
        <BlockInfo
          imagen={blockInfo1}
          titulo="Productores"
          subtitulo="Analizá las mejores oportunidades para planificar tu campaña"
          boton=""
        />
        <BlockInfo
          imagen={blockInfo2}
          titulo="Proveedores"
          subtitulo="Accedé a herramientas de financiacíon para evolucionar tu manera de vender"
          boton=""
        />
      </BlockContainer>
      <Brands />
      <TractorBanner
        icono={bolsaIcon}
        titulo="Además vas a poder"
        list1="Ampliar tus posibilidades de pagos y financiamientos con medios bancarios"
        list2="Utilizar tarjetas del agro como medio de pago"
        list3="Contratar cobertura de precios"
        list5="Automatizar tus relaciones contractuales mediante Smart Contracts"
        list4="Operar con Echeqs"
        imagen={tractor}
      />
      <Form />
      <PreguntasFrecuentes />
    </main>
  );
};

export default Home;
