import React, { useEffect } from 'react'

import Container from '../components/Productor/Container'
import Header from '../components/Productor/Header'
import Opera from '../components/Productor/Opera'
import Card from '../components/Productor/Card'
import pay from '../components/Productor/img/pay.svg'
import cel from '../components/Productor/img/cel.svg'
import choice from '../components/Productor/img/choice.svg'
import Financiacion from '../components/Productor/Financiacion'
import Buscador from '../components/Productor/Buscador'
import ComoFunciona from '../components/Productor/ComoFunciona'
import Contacto from '../components/Productor/Contacto'


const Productor = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <main className="productor">
        <Header />
        <Container>
          <Opera />
         <div className='container-card'>
          <Card 
              icon={pay}
              titulo='Visualizá todas las opciones de pago y financiación en un solo lugar'
              descripcion='Vinculá los métodos que usás habitualmente (billeteras de granos, bancos) y encontrá las mejores ofertas de convenios. Accedé a las tasas más competitivas para insumos y maquinarias.'
            />
            <Card 
              icon={cel}
              titulo='Compará'
              descripcion='No gastes tu tiempo analizando las múltiples ofertas que te llegan por distintos canales, acá vas a tener todo. Te facilitaremos la comparación para que puedas elegir la mejor alternativa.'
            />
            <Card 
              icon={choice}
              titulo='Tomá la mejor decisión'
              descripcion='Sabemos que es imposible dedicarle tiempo a todo por eso queremos ayudarte a que tomes las mejores decisiones financieras. Nuestras alternativas de pago y financiación se adecúan a tu ciclo productivo.'
            />
         </div>
         <Financiacion />
         <Buscador />
         <ComoFunciona />
        </Container>
        <Contacto />
    </main>
  )
}

export default Productor