import React, { useEffect } from 'react'

const Stores = () => {


    useEffect(() => {
        
        var isMobile = {
            Android: () => {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: () => {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: () => {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: () => {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: () => {
                return navigator.userAgent.match(/IEMobile/i);
            },
            any: () => {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
            }

        };

        if ( isMobile.Android() ) {
            document.location.href = "https://play.google.com/store/apps/details?id=com.nera.neraagro";
        }
        else if(isMobile.iOS())
        {
            document.location.href = "https://apps.apple.com/app/nera/id1667637863";
        }else {
            document.location.href = "https://nera-agro.com/";
        }

      },[])

  return (
    <div></div>
  )
}

export default Stores