import React from "react";

//Joe las de abajo
const BlockInfo = (props: any) => {

  const { imagen, titulo, subtitulo, boton } = props;

  return (
      <div className="block-info">
        <img src={imagen} alt=''/>
        <div className="bajada">
          <h5>{titulo}</h5>
          <p>{subtitulo}</p>
          <span>{boton}</span>
        </div>
      </div>
  )
};

export default BlockInfo;
