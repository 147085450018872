import React from "react";

//Guido las cards de arriba
const CardInfo = ({
  iconPath,
  titulo,
  descripcion,
}: {
  iconPath: string;
  titulo: string;
  descripcion: string;
}) => {
  return (
    <div className="card-info">
      <img src={iconPath} alt="" />
      <div>
        <p className="titulo">{titulo}</p>
        <span className="descripcion">{descripcion}</span>
      </div>
    </div>
  );
};

export default CardInfo;
