import React from 'react'
import linea from '../Proveedor/img/linea.svg'
import appStore from '../Proveedor/img/app-store.svg'
import googlePlay from '../Proveedor/img/google-play.svg'


const Header = () => {
  return (
    <div className='header-proveedor'> 
        <div className='overlay'>
            <div className='header-container'>
                <p>Te ayudamos a vender más y <br/>acceder a la red más importante <br/>de productores agro</p>
                <img src={linea} alt=''/>
                <div className='img-container'>
                  <a href='https://apps.apple.com/app/nera/id1667637863' target='_blank' rel="noreferrer">
                    <img src={appStore} alt=''/>
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.nera.neraagro' target='_blank' rel="noreferrer">
                    <img src={googlePlay} alt=''/>
                  </a>
              </div>
            </div>  
        </div>
  </div>
  )
}

export default Header