import React from "react";

//Joe
const LaptopBanner = (props: any) => {
  const { imagen, imagenMobile, icon, titulo, badget1, badget2, badget3, texto } = props;

  return (
    <div className="laptop-banner">
      <div className="laptop">
        <img className='desktop' src={imagen} alt="" />
        <img className='mobile' src={imagenMobile} alt='' />
      </div>
      <div className="info">
        <img src={icon} alt="" />
        <h3>{titulo}</h3>
        <div className="flex-container">
          <span>{badget1}</span>
          <span>{badget2}</span>
          <span>{badget3}</span>
        </div>
        <p>{texto}</p>
      </div>
    </div>
  );
};

export default LaptopBanner;
