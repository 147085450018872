const server = "https://website.nera-agro.com";

export const handleFormSubmit = async (formData: any) => {
  const res = await fetch(server + "/api/contactos", {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  return json;
};
