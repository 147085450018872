import React from 'react'
import foto from '../Proveedor/img/foto-1.png'
import cobrarIcon from '../Proveedor/img/cobrar-icon.svg'
import linea2 from '../Proveedor/img/linea-2.svg'


const Cobrar = () => {
  return (
    <div className='cobrar'>
        <div className='flex-container'>
            <div className='foto'>
                <img src={foto} alt=''/>
            </div>
            <div className='content'>
                <img src={cobrarIcon} alt=''/>
                <p>Vas a poder <b>cobrar tus ventas de <br/>contado inmediato</b> y ofrecer distintas <br/><b>formas de financiamiento.</b></p>
            <img src={linea2} alt=''/>
        </div>
        </div>
       
    </div>
  )
}

export default Cobrar