import React from 'react'
import FormSection from '../FormSection'
import contactoImagen from './img/contacto-imagen1.png'
import contactoIcon from './img/contacto-icon.svg'

const Contacto = () => {
  return (
    <div className='contacto' id='contacto'>
        <div className='flex-container'>
            <div className='foto'>
                <img src={contactoImagen} alt=''/>
            </div>
            <div className='formulario'>
                <h3><img src={contactoIcon} alt=''/>Contacto</h3>
                <p>Envianos tu mensaje en el siguiente formulario y te responderemos a la brevedad</p>
                <FormSection />
            </div>
        </div>
    </div>
  )
}

export default Contacto