import React from 'react'

import neraLogo from './img/nera-logo.svg'
import linea from './img/linea.svg'


const ComoFunciona = () => {
  return (
    <div className='como-funciona' id='ayuda'>
        <img className='logo' src={neraLogo} alt='' />
        <h3>¿Cómo funciona Nera?</h3>
        <img className='linea' src={linea} alt=''/>
        <div className='container'>
          <iframe className='video' src="https://www.youtube.com/embed/qDD8J6k4hAo?controls=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
        </div>
    </div>
  )
}

export default ComoFunciona