import React from "react";

//Joe
const TractorBanner = (props: any) => {
  const { icono, titulo, list1, list2, list3, list4, list5, imagen } = props;

  return (
    <div className="tractor-banner">
      <div className="info">
        <img src={icono} alt="icono" />
        <h3>{titulo}</h3>
        <ul>
          <li>{list1}</li>
          <li>{list2}</li>
          <li>{list3}</li>
          <li>{list4}</li>
          <li>{list5}</li>
        </ul>
      </div>
      <div className="tractor">
        <img src={imagen} alt="" />
      </div>
    </div>
  );
};

export default TractorBanner;
