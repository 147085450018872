import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/main.css";
import Footer from "./components/Footer";
import Navegacion from "./components/Navegacion";
import Home from "./pages/Home";
import Productor from "./pages/Productor";
import Proveedor from "./pages/Proveedor";
import Stores from "./pages/Stores";

const App = () => {
  return (
    <BrowserRouter>
      <Navegacion />
      <div className="container-general">
      <Routes>
          <Route path="*" element={<Home />}/>
          <Route path="/productor" element={<Productor />}/>
          <Route path="/proveedor" element={<Proveedor />}/>
          <Route path="/stores" element={<Stores />}></Route>
      </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
