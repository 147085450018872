import React, { useRef } from "react";
import Boton from "./Boton";
import Swal from "sweetalert2";
import { handleFormSubmit } from "../functions/APICalls";
import emailjs from '@emailjs/browser';

//Guido
const FormSection = () => {
  const [formData, setFormData] = React.useState({});

  const handleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const fireSwal = (error?: string) => {
    if (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "Reintentar",
        confirmButtonColor: "red",
      });
    } else {
      Swal.fire({
        title: "Exito!",
        text: "Tu mensaje se ha enviado. Nos pondremos en contacto brevemente",
        icon: "success",
        confirmButtonText: "Continuar",
        confirmButtonColor: "#009b41",
      });
    }
  };


  // Integra emailJS library para mandar la data del form a un mail
  const form = useRef<HTMLFormElement>(null!)
  const sendEmail = (e:any) => {
    e.preventDefault();

    // Manda mail a proveedores@nera-agro.com (configuracion de templates en EmailJS)
    window.location.href.indexOf("proveedor") > -1 
    ?
      emailjs.sendForm('service_0so2wlq', 'template_4kkj378', form.current, 'kBPIKeRu2iMl6KQu0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      })
    // Manda mail a productores@nera-agro.com (configuracion de templates en EmailJS)
    :
      emailjs.sendForm('service_0so2wlq', 'template_0eawrha', form.current, 'kBPIKeRu2iMl6KQu0')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
  } 
 
  return (
    <>
    <form ref={form} onSubmit={sendEmail}>
      <div className="form-section">
        <div className="input-flex">
          <div className="label-input">
            <label htmlFor="nombre">Nombre y Apellido*</label>
            <input
              required
              type="text"
              name="nombre"
              onChange={(e) => handleChange(e.target.value, "nombre")}
            />
          </div>
          <div className="label-input">
            <label htmlFor="empresa">Empresa</label>
            <input
              type="text"
              name="empresa"
              onChange={(e) => handleChange(e.target.value, "empresa")}
            />
          </div>
        </div>
        <div className="input-flex">
          <div className="label-input">
            <label htmlFor="telefono">Teléfono*</label>
            <input
              required
              type="text"
              name="telefono"
              onChange={(e) => handleChange(e.target.value, "telefono")}
            />
          </div>
          <div className="label-input">
            <label htmlFor="email">Email*</label>
            <input
              required
              type="text"
              name="email"
              onChange={(e) => handleChange(e.target.value, "email")}
            />
          </div>
        </div>
        <div className="label-input">
          <label htmlFor="mensaje">Mensaje*</label>
          <textarea
            name="mensaje"
            id=""
            cols={30}
            rows={10}
            onChange={(e) => handleChange(e.target.value, "mensaje")}
          ></textarea>
        </div>
      </div>
      <div className="form-bottom">
        <div>
          <p className="mb-1">Los campos marcados con * son obligatorios.</p>

          <p>
            Al enviar este formulario, acepta que sus datos personales sean
            tratados conforme con nuestra
            <span>Política de tratamiento de Datos Personales.</span>
          </p>
        </div>
        <Boton
          onClick={() => {
            handleFormSubmit(formData)
              .then((json) => {
                console.log(json);
                if (json.status === "OK") {
                  fireSwal();
                }
                fireSwal(json.errDesc);
              })
              .catch((e) => fireSwal(e));
          }}
        >
          Enviar
        </Boton>
      </div>
    </form>  
    </>
  );
};

export default FormSection;
