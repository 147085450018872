import React, { useEffect } from 'react'
import Beneficios from '../components/Proveedor/Beneficios'
import Cobrar from '../components/Proveedor/Cobrar'
import Contacto from '../components/Proveedor/Contacto'
import Header from '../components/Proveedor/Header'

const Proveedor = () => {

    useEffect(() => {
        window.scrollTo(0,0)
      },[])

  return (
    <div className='proveedor'>
        <Header />
        <Cobrar />
        {/* <Insumos /> */}
        <Beneficios />
        <Contacto />
    </div>
  )
}

export default Proveedor