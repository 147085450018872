import React from 'react'

const Card = (props : any) => {

    const { icon, titulo, descripcion } = props;

    return (
      <div className="card">
        <img src={icon} alt="" />
        <h6 className="titulo">{titulo}</h6>
        <p className="descripcion">{descripcion}</p>
      </div>
    );
  };

export default Card


