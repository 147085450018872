import React from "react";

const CardContainer = ({ children }: { children: any }) => {
  return (
    <section className="nera-container">
      <div className="card-container">{children}</div>
    </section>
  );
};

export default CardContainer;
