import React from 'react'
import linea from './img/linea-3.svg'
import circle from './img/circle.svg'

const Beneficios = () => {
  return (
    <div className='beneficios'>
        <div className='flex-container'>
            <div className='titulo'>
                <span>Ofrecemos</span>
                <h3>Beneficios para vos y <br/>tus clientes</h3>
                <img src={linea} alt=''/>
            </div>
            <div className='lista'>
                <div className='overlay'>
                    <div className=''>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Podés ofrecer distintas formas de financiamiento, con cobro de contado</span>
                        </div>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Préstamos a cosecha</span>
                        </div>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Préstamos a 6, 12, 18 y 24 cuotas</span>
                        </div>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Pago con granos disponibles </span>
                        </div>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Pago con granos con entrega a futuro (Forward)</span>
                        </div>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Posibilidad de mejorar la oferta financiera a través de subsidio de tasa.</span>
                        </div>
                        <div className='flex-container'>
                            <img src={circle} alt=''/>
                            <span>Cero riegos de incobrabilidad</span>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
  )
}

export default Beneficios