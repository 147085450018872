import React from 'react'
import rectangle from '../assets/img/rectangle.svg'

const BlockInfoText = () => {
  return (
    <div className='block-info-text'>
        <h3>Acompañamos a la cadena de valor del agro<br/><img src={rectangle} alt=''/></h3>
    </div>
  )
}

export default BlockInfoText