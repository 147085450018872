import React from "react";
import { useNavigate } from "react-router-dom";
import Boton from "./Boton";
import Dropdown from "./Dropdown";
import LoginDropdown from "./LoginDropdown";

//Guido
const Navegacion = () => {
  const [dropdownActive, setDropdownActive] = React.useState(false);
  const [loginDrodownActive, setLoginDropdownActive] = React.useState(false);

  const navigate = useNavigate();
 
  const handleLoginDropdown = () => {
    setLoginDropdownActive(!loginDrodownActive);
  };

  const handleClickScrollInicio = () => {
    const element = document.getElementById('inicio');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else navigate("/")
  };

  const handleProductor = () => {
    navigate("/productor")
  } 

  const handleProveedor = () => {
    navigate("/proveedor")
  } 

  const handleClickScrollAyuda = () => {
    const element = document.getElementById('ayuda');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleClickScrollContacto = () => {
    const element = document.getElementById('contacto');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <nav className="navegacion">
        <a href='/'>
          <img src="/img/nera-logo.png" alt="" />
        </a>
        <div className="navegacion-flex">
          <p onClick={handleClickScrollInicio}>Inicio</p>
          <p onClick={handleProductor}>Productor</p>
          <p onClick={handleProveedor}>Proveedor</p>
          <a href="https://convenios.nera-agro.com/" target='_blank' rel="noreferrer"><p>Convenios</p></a>
          {(window.location.href.indexOf("proveedor") > -1) ?
            null :
            <p onClick={handleClickScrollAyuda}>Ayuda</p>
          }
          <p onClick={handleClickScrollContacto}>Contacto</p>
          <Boton onClick={handleLoginDropdown}>iniciá sesión</Boton>
        </div>
        {loginDrodownActive && <LoginDropdown />}
        {!dropdownActive ? (
          <img
            src="/img/borgir.svg"
            alt=""
            className="hamburger"
            onClick={() => {
              setDropdownActive(true);
            }}
          />
        ) : (
          <>
            <img
              src="/img/cross.svg"
              alt=""
              className="hamburger"
              onClick={() => {
                setDropdownActive(false);
              }}
            />
            <Dropdown />
          </>
        )}
      </nav>
    </>
  );
};

export default Navegacion;
