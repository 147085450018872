import React from "react";

import FormSection from "./FormSection";

//Guido
const Form = () => {
  return (
    <section className="nera-container-fluid pb-5" id='contacto'>
      <div className="row row-cols-1 row-cols-lg-2 g-0">
        <div className="col form-img">
          <img src="/img/form-img.png" alt="" />
        </div>
        <div className="col form-col">
          <img src="/img/User.svg" alt="" />
          <FormSection />
        </div>
      </div>
    </section>
  );
};

export default Form;
