import React from 'react'
import vector from './img/vector.svg'
import agripay from './img/agripay.svg'

const Financiacion = () => {
  return (
    <div className='financiacion'>
        <div className='flex-container'>
            <div className='titulo'>
                <h3>Opciones de <br/>financiación</h3>
                <img src={vector} alt=''/>
            </div>
            <div className='cards'>
              <div className='tarjeta'>
                <h6>A sola firma</h6>
                <p>El proveedor que elegiste, cargará una orden en Nera para que la pagues de contado con un crédito inmediato otorgado por tu banco.</p>
              </div>
              <div className='tarjeta'>
                <h6>Cesión de Forward</h6>
                <p>El proveedor que elegiste, cargará una orden en Nera para que la pagues de contado con un crédito inmediato otorgado por tu banco. Deberás realizar un contrato forward que representará la garantía para la entidad financiera, quien otorgará la financiación. Al ser un crédito garantizado, vas a poder acceder a una tasa diferencial.</p>
              </div>
              <div className='tarjeta'>
                <h6>Pagá con granos <a href='https://agripay.ar/' target='_blank' rel="noreferrer"><img src={agripay} alt=''/></a></h6>  
                <p>El proveedor que elegiste, cargará una orden en Nera para que la pagues con la venta de granos disponibles que tengas en tu billetera de Agripay.</p>
                <a href='https://agripay.ar/' target='_blank' rel="noreferrer">
                  <button>Conocé más</button>
                </a>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Financiacion