import React from "react";

//Guido
const Footer = () => {
  return (
    <footer className="nera-footer">
      <div className="derechos">
        <p>Copyright 2022. Todos los derechos reservados.</p>
        <p>
          Prohibida la duplicación, distribución o almacenamiento en cualquier
          medio.
        </p>
      </div>
      <div className="logo-y-redes">
        <div className="redes">
          <a href='https://www.facebook.com/nera.agro' target='_blank' rel="noreferrer">
            <img src="/img/fb.png" alt="" />
          </a>
          <a href='https://www.instagram.com/nera.agro/' target='_blank' rel="noreferrer">
            <img src="/img/instagram.png" alt="" />
          </a>
          <a href='https://www.linkedin.com/company/nera-agro/' target='_blank' rel="noreferrer">
            <img src="/img/linkedin.png" alt="" />
          </a>
          <a href='https://twitter.com/nera_agro' target='_blank' rel="noreferrer">
            <img src="/img/twitter.png" alt="" />
          </a>
          <a href='https://www.youtube.com/@nera-agro' target='_blank' rel="noreferrer">
            <img src="/img/youtube.png" alt="" />
          </a>
        </div>
        <img src="/img/nera-logo-blanco.png" alt="" />
      </div>
    </footer>
  );
};

export default Footer;
