import React from 'react'
import foto from '../Productor/img/foto-1.png'
import laptop from '../Productor/img/laptop-icon.svg'
import checkmark from '../Productor/img/checkmark.svg'

const Opera = () => {
  return (
    <div className='opera'>
        <div className='flex-container'>
            <div className='col-1'>
                <div className='titulo'>
                    <img src={laptop} alt=''/>
                    <h3>Operá de forma simple <br/>y 100% digital</h3>
                </div>
                <div className='item'>
                    <img src={checkmark} alt=''/>
                    <p>Todos tus medios de pago y financiación <br/>en un solo lugar.</p>    
                </div>
                <div className='item'>
                    <img src={checkmark} alt=''/>
                    <p>Trabajamos con todos los laboratorios, agronomías, acopios, exportadores y corredores. (La mayoría de tus proveedores opera con nosotros).</p>    
                </div>
                <div className='item'>
                    <img src={checkmark} alt=''/>
                    <p>Entendemos tu ciclo productivo, por eso tenemos mejores tasas y  vencimientos flexibles.</p>    
                </div>
            </div>
            <div className='col-2'>
                <img src={foto} alt=""/>
            </div>
        </div>
    </div>
  )
}

export default Opera