import React, { useState } from "react";
import arrowDown from "../assets/img/arrow-down.svg";
import arrowUp from "../assets/img/arrow-up.svg";

//Joe
const PreguntasFrecuentes = () => {
  const [selected, setSelected] = useState(null);
  const toogle = (index: any) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  const preguntas = [
    {
      pregunta: <h3>¿Qué es Nera?</h3>,
      respuesta: (
        <p>
          Somos un ecosistema digital que hace que tu gestión agrofinanciera sea
          simple, práctica e integrada. Contamos con funcionalidades de pago y
          financiación para ayudarte en la gestión de tu negocio, siendo parte
          de una conexión eficiente, sustentable y líder en el escenario global
          del agro.
        </p>
      ),
    },
    {
      pregunta: <h3>¿Qué requisitos debo cumplir para sumarme a Nera?</h3>,
      respuesta: (
        <p>
          Actualmente solo dos requisitos: <br />
          1. Tener cuenta corriente en Banco Galicia. <br />
          2. Realizar actividades relacionadas al agro.
        </p>
      ),
    },
    {
      pregunta: <h3>¿Nera tiene algún costo?</h3>,
      respuesta: <p>El uso de la plataforma no tiene costos asociados.</p>,
    },
    {
      pregunta: (
        <h3>¿En qué horario estan disponibles las consultas y operaciones?</h3>
      ),
      respuesta: (
        <p>
          Podrás gestionar órdenes y consultar operaciones durante los 365 días
          del año las 24 hs.
        </p>
      ),
    },
    {
      pregunta: <h3>¿Cómo ingreso a plataforma Nera?</h3>,
      respuesta: (
        <p>
          1. Ingresá a nera-agro.com <br />
          2. Completá el registro con tus datos. <br />
          3. Una vez que completes los datos recibirás un mail para validar tu
          casilla de correo.
        </p>
      ),
    },

    {
      pregunta: <p>Olvidé mi contraseña</p>,
      respuesta: (
        <p>
          1. Ingresá a nera-agro.com para iniciar sesión y seleccioná la opción
          "Recuperala aquí". <br />
          2. Ingresá tu correo electrónico con el cual te registraste. <br /> 3.
          Te llegará un mail a tu correo con un link de verificación. Hacé clic
          y listo.
        </p>
      ),
    },
  ];

  return (
    <div className="preguntas-frecuentes" id="ayuda">
      <h3>Preguntas frecuentes</h3>

      <div className="flex-container">
        {preguntas.map((item, index) => (
          <div key={index} className="item">
            <div className="title" onClick={() => toogle(index)}>
              {item.pregunta}
              {selected === index ? (
                <img src={arrowUp} alt="" />
              ) : (
                <img src={arrowDown} alt="" />
              )}
            </div>
            <div className={selected === index ? "content active" : "content"}>
              {item.respuesta}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
