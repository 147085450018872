import React from "react";

//Guido
const IlustracionInfo = () => {
  return (
    <section className="nera-container">
      <div className="row row-cols-1 row-cols-lg-2 g-0">
        <div className="col ilustracion-texto">
          <h1>
          Somos una plataforma digital que optimiza la gestión financiera del agro y la hace <span>simple, práctica e integrada.</span>
          </h1>
        </div>
        <div className="col ilustracion-img">
          <img src="/img/nera-ilustracion.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default IlustracionInfo;
