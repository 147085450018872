import React from "react";
import { useNavigate } from "react-router-dom";
import Boton from "./Boton";
import LoginDropdown from "./LoginDropdown";

const Dropdown = () => {
  const [loginDrodownActive, setLoginDropdownActive] = React.useState(false);

  const handleLoginDropdown = () => {
    setLoginDropdownActive(!loginDrodownActive);
  };

  const navigate = useNavigate();
  
  const handleClickScrollInicio = () => {
    const element = document.getElementById('inicio');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else navigate("/")
  };

  const handleProductor = () => {
    navigate("/productor")
  } 

  const handleProveedor = () => {
    navigate("/proveedor")
  } 

  const handleClickScrollAyuda = () => {
    const element = document.getElementById('ayuda');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleClickScrollContacto = () => {
    const element = document.getElementById('contacto');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <div className="dropdown active">
      <p onClick={handleClickScrollInicio}>Inicio</p>
      <p onClick={handleProductor}>Productor</p>
      <p onClick={handleProveedor}>Proveedor</p>
      <a href="https://convenios.nera-agro.com/" target='_blank' rel="noreferrer"><p>Convenios</p></a>
      {(window.location.href.indexOf("proveedor") > -1) ?
        null :
        <p onClick={handleClickScrollAyuda}>Ayuda</p>
      }
      <p onClick={handleClickScrollContacto}>Contacto</p>
      {loginDrodownActive && <LoginDropdown />}
      <Boton onClick={handleLoginDropdown}>iniciá sesión</Boton>
    </div>
  );
};

export default Dropdown;
