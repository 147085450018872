import React from "react";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import brand1 from '../assets/img/brand1.svg';
import brand2 from '../assets/img/brand2.svg';
import brand3 from '../assets/img/brand3.svg';
import brand4 from '../assets/img/brand4.svg';
import brand5 from '../assets/img/brand5.svg';
import brand6 from '../assets/img/brand6.svg';
import brand7 from '../assets/img/brand7.svg';
import brand8 from '../assets/img/brand8.svg';
import brand9 from '../assets/img/brand9.svg';
import brand10 from '../assets/img/brand10.svg';
import brand11 from '../assets/img/brand11.svg';
import brand12 from '../assets/img/brand12.svg';



//Joe

const Brands = () => {

  const slider = React.useRef(null);


  const brands = [
    {
      brand: brand1,
      alt: 'bunge'
    },
    {
      brand: brand2,
      alt: 'bayer'
    },
    {
      brand: brand3,
      alt: 'dekalb'
    },
    {
      brand: brand4,
      alt: 'nutrien'
    },
    {
      brand: brand5,
      alt: 'syngenta'
    },
    {
      brand: brand6,
      alt: 'upl'
    },
    {
      brand: brand7,
      alt: 'ypf'
    },
    {
      brand: brand8,
      alt: 'roundup'
    },
    {
      brand: brand9,
      alt: 'atanor'
    },
    {
      brand: brand10,
      alt: 'yara'
    },
    {
      brand: brand11,
      alt: 'ypfAgrro'
    },
    {
      brand: brand12,
      alt: 'kws'
    },
  ]

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="brands-title">
        <h3>Nos acompañan:</h3>
      </div>
      <div className="brands">
        <div className='slider'>
          <Slider ref={slider} {...settings}>
            {brands.map((item) => (
              <div className="fotos">
                <img src={item.brand} 
                alt={item.alt} 
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </> 
  );
};

export default Brands;
