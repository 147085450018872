import React from 'react'
import appStore from '../Productor/img/app-store.svg'
import googlePlay from '../Productor/img/google-play.svg'


const Header = () => {
  return (
    <div className='header-productor'> 
      <div className='overlay'>
        <div className='img-container'>
          <a href='https://apps.apple.com/app/nera/id1667637863' target='_blank' rel="noreferrer">
            <img src={appStore} alt=''/>
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.nera.neraagro' target='_blank' rel="noreferrer">
            <img src={googlePlay} alt=''/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header