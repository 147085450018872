import React from "react";

//Guido
const Boton = ({
  children,
  onClick,
}: {
  children: any;
  onClick?: Function;
}) => {
  return (
    <button className="nera-btn" onClick={onClick as any}>
      {children}
    </button>
  );
};

export default Boton;
