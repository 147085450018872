import React from "react";

//Guido
const Header = () => {
  return (
    <header className="header-banner row g-0" id="inicio">
      <div className="col-12 col-lg-5 wt-flex header-col-1">
        <div className="welcome-flex">
          <h4>
            El agro se transforma, <br />
            <span>tu campo también.</span>
          </h4>
          <p>
            Venimos a ofrecerte una <span>nueva perspectiva</span> para que
            puedas tomar decisiones más ágiles y eficientes en tu gestión
            agrofinanciera.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-7 header-col-2">
        <img src="/img/nera-header.png" alt="" />
      </div>
    </header>
  );
};

export default Header;
