import React from "react";

const LoginDropdown = () => {
  return (
    <div className="login-dropdown">
      <a href="https://productor.nera-agro.com/"><p>Productor</p></a>
      <a href="https://proveedor.nera-agro.com/iniciar-sesion/"><p>Proveedor</p></a>
    </div>
  );
};

export default LoginDropdown;
