import React from 'react'
import buscadorIcon from './img/buscador-icon.svg'
import lupaIcon from './img/lupa-icon.svg'


const Buscador = () => {
  return (
    <div className='buscador'>
        <div className='flex-container'>
            <div>
                <img src={buscadorIcon} alt=''/>
            </div>
            <div>
                <h3>Buscador de convenios</h3>
                <p>Encontrá los mejores acuerdos <br/><b>para financiar tus compras</b></p>
                <a href='https://convenios.nera-agro.com/' target='_blank' rel="noreferrer">
                    <button><img src={lupaIcon} alt=''/>Ir al buscador</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Buscador